import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import PageHeader from "../../components/pageHeader"
import SEO from "../../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching2 from '../../components/images/imageCoaching2.js'
import ImageTransition from '../../components/images/imageTransition.js'
import ImageEmploi from '../../components/images/imageEmploi.js'
import ImageLeft3 from '../../components/images/imageLeft3.js'

const Developpement = () => (
  <Layout>
    <SEO title="Développement de compétences relationnelles ou de gestion | Coaching | Gestion Mieux-Être" />
    <PageHeader
        header="Développement de compétences relationnelles ou de gestion"
        text="Nous vous soutenons de sorte que vous interagissiez avec pertinence avec tous :  votre équipe de collaborateurs, vos collègues et votre patron. La clé : développer votre capital relationnel est davantage une question d’attitude que de compétences.  "
        quote="S’il est vrai qu’on ne peut changer les gens, on peut certainement transformer le rapport que l’on a avec eux et cela entraîne le plus souvent des changements qui dépassent ce que l’on aurait pu imaginer <span>- Benjamin Zander (Chef d’orchestre du Philharmonique de Boston)</span>"
        background="coaching" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">
          <p class="service-presentation2">Misez sur un bilan des compétences et des stratégies de développement adaptées à vos besoins spécifiques.  Nous vous proposons des outils simples qui vous soutiendront de façon durable afin de vous distinguer.</p>

        <p class="service-presentation2">Investissez dans vos habiletés de gestion pour atteindre des résultats percutants!<br /><br />Explorons ensemble comment:</p>
        <div className="flex-container pad2">
            <div className="half">
                <ul className="success">
                    <li>Maximiser les impacts positifs de votre leadership</li>
                    <li>Développer votre courage managérial</li>
                    <li>Favoriser l’épanouissement de votre équipe</li>
                    <li>Maximiser vos communications interpersonnelles</li>
                    <li>Dénouer les conflits avec doigté</li>
                    <li>Naviguer avec aisance grâce à vos habiletés politiques</li>
                </ul>
            </div>
            <div className="half"><ImageLeft3 /></div>
        </div>
        <p class="service-presentation2">Nous vous secondons dans vos questionnements, vos réflexions ainsi que dans l'élaboration de vos plans d'action. Nous participons activement au développement de votre croissance professionnelle.  <br /><br />Nous vous aidons à saisir avec perspicacité votre impact sur votre environnement… et ainsi vous démarquer!</p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
        <div className="upperline"></div>
        <br /><br />
        <h1>Services connexes</h1>
        <br /><br /><br /><br />
        <div className="flex-container2">
            <ScrollAnimation
                delay="20"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/integration-a-de-nouvelles-fonctions">
                    <ImageCoaching2 />
                    <h6>Intégration à de nouvelles fonctions de gestion</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="30"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/transition-de-carriere">
                    <ImageTransition />
                    <h6>Transition de carrière</h6>
                </Link>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
                delay="40"
                animateIn="fadeIn"
                className="third">
            <div>
                <Link to="coaching/recherche-emploi">
                    <ImageEmploi />
                    <h6>Recherche d’emploi</h6>
                </Link>
            </div>
            </ScrollAnimation>
        </div>
    </div>
  </Layout>
)

export default Developpement
